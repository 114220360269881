function removeTrailingSlash (val) {
  if (typeof val === 'undefined') return
  return val.replace(/\/$/, '')
}

export const currentUrlParams = new URLSearchParams(window.location.search)

export const cloudApiBaseUrl = removeTrailingSlash(process.env.VUE_APP_CLOUD_API_BASE_URL) || 'http://localhost:8080'

export const iframeModeEnabled = currentUrlParams.get('iframe')
