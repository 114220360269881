import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/normalize.css'
import '@/assets/skeleton.css'
import 'vanilla-cookieconsent/dist/cookieconsent.css'
import 'vanilla-cookieconsent/dist/cookieconsent.js'
import VueSlider from "vue-slider-component"

// require iframeResizer
import iframeResizeContentWindow from 'iframe-resizer/js/iframeResizer.contentWindow'

Vue.config.productionTip = false
Vue.prototype.$iframeResizeContentWindow = iframeResizeContentWindow

Vue.component('VueSlider', VueSlider)

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
