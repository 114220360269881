import Vue from 'vue'
import VueRouter from 'vue-router'

// 1. Define route components.
const Leadgenerator = () => import('@/components/Leadgenerator')
const NotFoundError = () => import('@/components/NotFoundError')


// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
    { path: '*', name: 'NotFound', component: NotFoundError },
    {
        path: '/:iframeKey/:leadgenId/:pageId?',
        name: 'Leadgenerator',
        component: Leadgenerator,
    },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = new VueRouter({
    routes, // short for `routes: routes`
    mode: 'history',
})

Vue.use(VueRouter)

export default router
